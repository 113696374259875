import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';

export default {
  // This function is called when the Vue plugin is installed
  install(Vue) {
    // Force light theme
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.COLOR_SCHEME, 'light');

    Vue.prototype.$isKindly = window.chatwootConfig.isKindly;
  },
};
